$primary: #3d2096;
$secondary: #262626;

$gray: #929292;

$success: #63b160;
$danger: #fa6557;
$warning: #efa755;

/* these are non- bootstrap variables. */
$content-bg: #FFF;
$list-bg: #FFF;
$navbar-bg: $primary;

$sidebar-bg: #fff;
$sidebar-highlight: $secondary;
$sidebar-selected: $primary;
$sidebar-selected-bg: #f5f5f5;
/* /these are non- bootstrap variables. */

$body-bg: lighten($secondary, 81%);
$body-color: #232832;
$headings-color: #1f232b;
$modal-content-bg: $content-bg;

$card-cap-bg: darken($content-bg, 15);
$card-bg: darken($content-bg, 4);

$form-label-color: #262626;
$form-label-font-weight: 700;
$input-bg: #fafafa;
$input-border-color: #e3e3e3;
$input-border-width: 1px;
$input-focus-bg: #FFF;
$input-placeholder-color: #cbcbcb;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: darken($primary, 10%);
$dropdown-link-hover-color: #fff;

$border-radius:    3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

$input-padding-x: 0.5rem;
$input-padding-y: 0.3rem;

$input-btn-padding-x: 0.7rem;
$input-btn-padding-y: 0.3rem;

$input-font-size: 0.95rem;
$input-btn-font-size: 0.95rem;

$form-check-input-width: 1.3em;

$font-family-base: 'Roboto';
$font-family-sans-serif: 'Roboto';
$headings-font-family: 'Roboto';

$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 2px;

$enable-responsive-font-sizes: true;

$table-hover-bg: #ececec;
