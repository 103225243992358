:root{
    --content-bg: #{$content-bg};
    --list-bg: #{$list-bg};
    --navbar-bg: #{$navbar-bg};

    --sidebar-bg: #{$sidebar-bg};
    --sidebar-highlight: #{$sidebar-highlight};
    --sidebar-selected: #{$sidebar-selected};
    --sidebar-selected-bg: #{$sidebar-selected-bg};

    // additional bootstrap variables to be exposed at :root
    --input-btn-padding-y: #{$input-btn-padding-y};
    --input-btn-padding-x: #{$input-btn-padding-x};
    --input-border-color: #{$input-border-color};
    --input-font-size: #{$input-font-size};
    --border-color: #{$border-color};
    --dropdown-bg: #{$dropdown-bg};
}
