@import '~src/scss/helpers/bootstrap_base';

html {
    overflow-y: scroll !important;
}

body {
    //overflow-y: scroll !important;
    padding-right: 0 !important;
    display: block;
    background-color: $navbar-bg;

    &.modal-open {
        overflow: unset;
        .modal {
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }

    // hack fix for ios pwa statusbar colour
    &:before {
        content: '';
        position:fixed;
        width: 100vw;
        height: 100px;
        top: -100px;
        left: 0;
        background-color: $navbar-bg;
        z-index: 10000000000;
    }
    &:after {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $body-bg;
        z-index: -1;
    }
}

.content-container {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;
    background-color: $content-bg;
    padding: 1em;
    margin-bottom: 3em;
    clear: both;
    @include media-breakpoint-down(md) {
        border-radius: 0;

        margin-left: -8px;
        margin-right: -8px;
        padding: 0.5rem!important;
    }
    @include media-breakpoint-down(sm) {
        padding: 0.6em;
    }
}


.app-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: visible;
    position: relative;

    background-color: $body-bg;

    //min-height: 100vh;

    &.top-bar {
        min-height: calc(100vh - 60px);
        padding-top: 60px;

        &.offline {
            min-height: calc(100vh - 80px);
            padding-top: 80px;
            &::ng-deep {
                .requests-wrapper, .requests {
                    height: calc(100vh - 80px);
                }
            }
        }

    }

    main {
        flex: 1;
        min-width: 0;
        position: relative;

        > .container-fluid {
            max-width: 1200px;
        }

        &.centered {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}
